import React from 'react'

export default function termsofService() {
  return (
    <>
      {localStorage.getItem('Lang') && localStorage.getItem('Lang') === 'en' ? (
        <>
          <div className="page">
            <div className="title">Website Terms and Conditions of Use</div>

            <h2>1. Terms</h2>

            <p>
              By accessing this Website, accessible from correctstore.com, you
              are agreeing to be bound by these Website Terms and Conditions of
              Use and agree that you are responsible for the agreement with any
              applicable local laws. If you disagree with any of these terms,
              you are prohibited from accessing this site. The materials
              contained in this Website are protected by copyright and trade
              mark law.
            </p>

            <h2>2. Use License</h2>

            <p>
              Permission is granted to temporarily download one copy of the
              materials on Correct Store's Website for personal, non-commercial
              transitory viewing only. This is the grant of a license, not a
              transfer of title, and under this license you may not:
            </p>

            <ul>
              <li>modify or copy the materials;</li>
              <li>
                use the materials for any commercial purpose or for any public
                display;
              </li>
              <li>
                attempt to reverse engineer any software contained on Correct
                Store's Website;
              </li>
              <li>
                remove any copyright or other proprietary notations from the
                materials; or
              </li>
              <li>
                transferring the materials to another person or "mirror" the
                materials on any other server.
              </li>
            </ul>

            <p>
              This will let Correct Store to terminate upon violations of any of
              these restrictions. Upon termination, your viewing right will also
              be terminated and you should destroy any downloaded materials in
              your possession whether it is printed or electronic format.
            </p>

            <h2>3. Disclaimer</h2>

            <p>
              All the materials on Correct Store’s Website are provided "as is".
              Correct Store makes no warranties, may it be expressed or implied,
              therefore negates all other warranties. Furthermore, Correct Store
              does not make any representations concerning the accuracy or
              reliability of the use of the materials on its Website or
              otherwise relating to such materials or any sites linked to this
              Website.
            </p>

            <h2>4. Limitations</h2>

            <p>
              Correct Store or its suppliers will not be hold accountable for
              any damages that will arise with the use or inability to use the
              materials on Correct Store’s Website, even if Correct Store or an
              authorize representative of this Website has been notified, orally
              or written, of the possibility of such damage. Some jurisdiction
              does not allow limitations on implied warranties or limitations of
              liability for incidental damages, these limitations may not apply
              to you.
            </p>

            <h2>5. Revisions and Errata</h2>

            <p>
              The materials appearing on Correct Store’s Website may include
              technical, typographical, or photographic errors. Correct Store
              will not promise that any of the materials in this Website are
              accurate, complete, or current. Correct Store may change the
              materials contained on its Website at any time without notice.
              Correct Store does not make any commitment to update the
              materials.
            </p>

            <h2>6. Links</h2>

            <p>
              Correct Store has not reviewed all of the sites linked to its
              Website and is not responsible for the contents of any such linked
              site. The presence of any link does not imply endorsement by
              Correct Store of the site. The use of any linked website is at the
              user’s own risk.
            </p>

            <h2>7. Site Terms of Use Modifications</h2>

            <p>
              Correct Store may revise these Terms of Use for its Website at any
              time without prior notice. By using this Website, you are agreeing
              to be bound by the current version of these Terms and Conditions
              of Use.
            </p>

            <h2>8. Your Privacy</h2>

            <p>Please read our Privacy Policy.</p>

            <h2>9. Governing Law</h2>

            <p>
              Any claim related to Correct Store's Website shall be governed by
              the laws of eg without regards to its conflict of law provisions.
            </p>
          </div>
        </>
      ) : (
        <>
          <div class="page ar">
            <div class="title">شروط وأحكام استخدام الموقع</div>
            <h2>1. الشروط</h2>
            <p>
              من خلال الوصول إلى هذا الموقع ، الذي يمكن الوصول إليه
              منrectstore.com ، فإنك توافق على الالتزام بشروط وأحكام استخدام
              موقع الويب هذه وتوافق على أنك مسؤول عن الاتفاقية مع أي قوانين
              محلية معمول بها. إذا كنت لا توافق على أي من هذه الشروط ، فيُحظر
              عليك الوصول إلى هذا الموقع. المواد الواردة في هذا الموقع محمية
              بموجب قانون حقوق النشر والعلامات التجارية.
            </p>
            <h2>2. ترخيص الاستخدام</h2>
            <p>
              يُمنح الإذن لتنزيل نسخة واحدة من المواد مؤقتًا على موقع Correct
              Store الإلكتروني للمشاهدة الشخصية غير التجارية العابرة فقط. هذا هو
              منح الترخيص ، وليس نقل الملكية ، وبموجب هذا الترخيص لا يجوز لك:
            </p>
            <ul>
              <li>تعديل أو نسخ المواد ؛</li>
              <li>استخدام المواد لأي غرض تجاري أو لأي عرض عام ؛</li>
              <li>
                محاولة إجراء هندسة عكسية لأي برنامج موجود على موقع Correct Store
                الإلكتروني ؛
              </li>
              <li>
                إزالة أي حقوق التأليف والنشر أو غيرها من تدوينات الملكية من
                المواد ؛ أو
              </li>
              <li>
                نقل المواد إلى شخص آخر أو "نسخ" المواد الموجودة على أي خادم آخر.
              </li>
            </ul>
            <p>
              سيسمح هذا لـ Correct Store بالإنهاء عند انتهاك أي من هذه القيود.{' '}
              عند الإنهاء ، سيتم أيضًا إنهاء حق المشاهدة الخاص بك ويجب عليك
              تدمير أي مواد تم تنزيلها في حوزتك سواء كانت مطبوعة أو بتنسيق
              إلكتروني.
            </p>
            <h2>3. إخلاء المسؤولية</h2>
            <p>
              يتم توفير جميع المواد الموجودة على موقع Correct Store "كما هي". لا
              يقدم كوركت ستور أي ضمانات ، صريحة أو ضمنية ، وبالتالي ينفي جميع
              الضمانات الأخرى. علاوة على ذلك ، لا يقدم Correct Store أي تعهدات
              تتعلق بدقة أو موثوقية استخدام المواد الموجودة على موقعه على الويب
              أو فيما يتعلق بهذه المواد أو أي مواقع مرتبطة بهذا الموقع.
            </p>
            <h2>4. القيود</h2>
            <p>
              لن يكون كوركت ستور أو مورديه مسؤولين عن أي أضرار قد تنشأ عن
              استخدام أو عدم القدرة على استخدام المواد الموجودة على موقع كوركت
              ستور ، حتى إذا تم إخطار كوركت ستور أو ممثل مفوض لهذا الموقع ،
              شفهيًا أو كتابيًا ، احتمال حدوث مثل هذا الضرر. لا تسمح بعض السلطات
              القضائية بفرض قيود على الضمانات الضمنية أو قيود المسؤولية عن
              الأضرار العرضية ، وقد لا تنطبق هذه القيود عليك.
            </p>
            <h2>5. التنقيحات و Errata</h2>
            <p>
              قد تتضمن المواد التي تظهر على موقع Correct Store الإلكتروني أخطاء
              فنية أو مطبعية أو فوتوغرافية. لن يعد كوركت ستور بأن أيًا من المواد
              الموجودة في هذا الموقع دقيقة أو كاملة أو حديثة. قد يقوم كوركت ستور
              بتغيير المواد الموجودة على موقعه على الويب في أي وقت دون إشعار. لا
              يقدم Correct Store أي التزام بتحديث المواد.
            </p>
            <h2>6. الروابط</h2>
            <p>
              لم يراجع Correct Store جميع المواقع المرتبطة بموقعه على الويب وهو
              غير مسؤول عن محتويات أي موقع مرتبط من هذا القبيل. لا يعني وجود أي
              رابط موافقة كوركت ستور للموقع. يتحمل المستخدم مسؤولية استخدام أي
              موقع مرتبط.
            </p>
            <h2>7. تعديلات شروط استخدام الموقع</h2>
            <p>
              يجوز لـ Correct Store مراجعة شروط الاستخدام لموقعه على الويب في أي
              وقت دون إشعار مسبق. باستخدام هذا الموقع ، فإنك توافق على الالتزام
              بالإصدار الحالي من شروط وأحكام الاستخدام هذه.
            </p>
            <h2>8. خصوصيتك</h2>
            <p>يرجى قراءة سياسة الخصوصية.</p>
            <h2>9. القانون الحاكم</h2>
            <p>
              تخضع أي مطالبة تتعلق بموقع Correct Store الإلكتروني لقوانين على
              سبيل المثال دون اعتبار لتعارضها مع أحكام القانون.
            </p>
          </div>
        </>
      )}
    </>
  )
}
