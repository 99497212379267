import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Home from './components/pages/home'
import Nav from './components/nav'
import Footer from './components/footer'
import Product from './components/pages/product'
import Products from './components/pages/products'
import Signin from './components/pages/signin'
import Signup from './components/pages/signup'
import Cart from './components/pages/cart'
import Washlist from './components/pages/washlist'
import Lang from './components/pages/lang'
import Profile from './components/pages/profile'
import TermsofService from './components/pages/termsofService'
import Privacypolicy from './components/pages/privacypolicy'

import Error404 from './components/pages/error404'
import Page from './components/pages/page'
function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Nav />
        <div className="co">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/signin" element={<Signin />} />
            <Route path="/register" element={<Signup />} />
            <Route path="/cart" element={<Cart />} />
            <Route path="/washlist" element={<Washlist />} />
            <Route path="/lang" element={<Lang />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/termsofService" element={<TermsofService />} />
            <Route path="/privacypolicy" element={<Privacypolicy />} />

            <Route path="/collections" element={<Products />} />
            <Route path="/pro/:productid/:productname" element={<Product />} />
            <Route path="/p/:pagename" element={<Page />} />
            <Route path="*" element={<Error404 />} />
          </Routes>
        </div>
        <Footer />
      </BrowserRouter>
    </div>
  )
}

export default App
