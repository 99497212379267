import React from 'react'
import '../style/footer.css'
import { Link } from 'react-router-dom'
export default function footer() {
  return (
    <>
      {localStorage.getItem('Lang') === 'en' ? (
        <div className="footer">
          <div className="fo-d">
            <div className="dop">
              <span className="logofooteren"></span>
            </div>
            <div className="dop">
              <Link to="/about">About Correct Store</Link>
              <Link to="/privacypolicy">privicy and site law</Link>
              <Link to="/termsofService">terms of Service</Link>
              <Link to="/faq">faq</Link>
            </div>
            <div className="dop">
              <div>Download Apps</div>
              <a href="/">
                <span className="downloadapp googleplay"></span>
              </a>
              <a href="/">
                <span className="downloadapp appstore"></span>
              </a>
            </div>
            <div className="dop">
              <div>connect with us</div>
              <div className="cosociel">
                <a href="/">
                  <span className="socielmedia facebook"></span>
                </a>
                <a href="/">
                  <span className="socielmedia instegram"></span>
                </a>
                <a href="/">
                  <span className="socielmedia twiter"></span>
                </a>
                <a href="/">
                  <span className="socielmedia whatsapp"></span>
                </a>
              </div>
            </div>
          </div>
          <div className="fo-d">
            all rights reserved . © <a href="//mbsoft-eg.com">MB SOFT inc</a>
          </div>
        </div>
      ) : (
        <>
          <div className="footer ar">
            <div className="fo-d">
              <div className="dop">
                <span className="logofooterar"></span>
              </div>
              <div className="dop">
                <Link to="/about">عن كوركت ستور</Link>
                <Link to="/privacypolicy">قوانين الموقع</Link>
                <Link to="/termsofService">شروط الاستخدام</Link>
                <Link to="/faq">الاسئله المتكرره و الدعم</Link>
              </div>
              <div className="dop">
                <div>حمل التطبيق</div>
                <a href="/">
                  <span className="downloadapp googleplay"></span>
                </a>
                <a href="/">
                  <span className="downloadapp appstore"></span>
                </a>
              </div>
              <div className="dop">
                <div>تواصل معنا</div>
                <div className="cosociel">
                  <a href="/">
                    <span className="socielmedia facebook"></span>
                  </a>
                  <a href="/">
                    <span className="socielmedia instegram"></span>
                  </a>
                  <a href="/">
                    <span className="socielmedia twiter"></span>
                  </a>
                  <a href="/">
                    <span className="socielmedia whatsapp"></span>
                  </a>
                </div>
              </div>
            </div>
            <div className="fo-d">
              جميع الحقوق محفوظه. © <a href="//mbsoft-eg.com">MB SOFT inc</a>
            </div>
          </div>
        </>
      )}
    </>
  )
}
