import React from 'react'
import '../../style/error.css'
export default function error404() {
  return (
    <>
      {localStorage.getItem('Lang') === 'en' ? (
        <>
          <div className="error">
            <span className="errorimage"></span>
            <div>404</div>
            <div>Sorry</div>
            <div>This page Not Found !</div>
          </div>
        </>
      ) : (
        <>
          <div className="error ar">
            <span className="errorimage"></span>
            <div>404</div>
            <div>للاسف</div>
            <div>الصفحه دي مش موجوده !</div>
          </div>
        </>
      )}
    </>
  )
}
