import React from 'react'
import Product from '../scenes/product'

export default function cart() {
  return (
    <>
      {localStorage.getItem('Lang') === 'en' ? (
        <>
          <div className="page">
            <div className="title">Cart</div>
            <div className="firstcollection en">
              <Product
                title="INES COVER UP"
                price="200"
                imgurl="Screenshot2022-10-20183350.jpg"
              />
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="page ar">
            <div className="title">عربة التسوق</div>
            <div className="firstcollection">
              <Product
                title="INES COVER UP"
                price="200"
                imgurl="Screenshot2022-10-20183350.jpg"
              />
            </div>
          </div>
        </>
      )}
    </>
  )
}
