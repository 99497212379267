import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import '../style/nav.css'
export default function Nav() {
  const [search, setinsearch] = useState('')
  const [navstyle, setnavstyle] = useState()
  const navigate = useNavigate()

  if (!localStorage.getItem('Lang')) {
    localStorage.setItem('Lang', 'ar')
  }
  const lang = localStorage.getItem('Lang')
  //   const pathname = window.location.pathname
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [navigate])
  function menuclick() {
    const slider = document.querySelector('.sidbar')
    const washcart = document.querySelector('.washcart')
    const x = window.matchMedia('(max-width : 600px)')
    // cheack for logo ar or en
    var logo
    if (localStorage.getItem('Lang') === 'en') {
      logo = document.querySelector('.logoen')
    } else {
      logo = document.querySelector('.logoar')
    }
    //cheack if opened device is mobile or another
    if (x.matches) {
      if (slider.classList.contains('active')) {
        const style = {
          display: 'none',
        }
        document.body.style.overflow = 'auto'
        logo.style.cssText = ' '
        washcart.style.cssText = ' '
        setnavstyle(style)
      } else {
        const style = {
          top: '50px',
          width: '100%',
          background: 'rgb(255 255 255)',
          height: '100vh',
          position: 'fixed',
          display: 'block',
        }
        document.body.style.overflow = 'hidden'
        logo.style.cssText =
          ' width: 250px !important;background-position: center;transition: 1s;'
        washcart.style.cssText = ' display: none'
        setnavstyle(style)
      }
    } else {
      if (slider.classList.contains('active')) {
        const style = {
          display: 'none',
        }
        document.body.style.overflow = 'auto'
        logo.style.cssText = ' '
        washcart.style.cssText = ' '
        setnavstyle(style)
      } else {
        const style = {
          top: '100px',
          width: '100%',
          background: 'rgb(255 255 255)',
          height: '100vh',
          position: 'fixed',
          display: 'block',
        }
        document.body.style.overflow = 'hidden'
        logo.style.cssText =
          ' width: 250px !important;background-position: center;transition: 1s;'
        washcart.style.cssText = ' display: none'
        setnavstyle(style)
      }
    }
    slider.classList.toggle('active')
  }
  useEffect(() => {
    const slider = document.querySelector('.sidbar')
    const washcart = document.querySelector('.washcart')
    // cheack for logo ar or en
    var logo
    if (localStorage.getItem('Lang') === 'en') {
      logo = document.querySelector('.logoen')
    } else {
      logo = document.querySelector('.logoar')
    }
    const style = {
      display: 'none',
    }
    document.body.style.overflow = 'auto'
    logo.style.cssText = ' '
    washcart.style.cssText = ' '
    setnavstyle(style)
    slider.classList.remove('active')
  }, [navigate])
  return (
    <>
      {lang && lang === 'en' ? (
        <>
          <div className="navbar">
            <div className="topnav">
              <Link to={'/lang'}>
                <div className="nav-1"> Egypt - English</div>
              </Link>
              <div className="nav-1">
                <div className="search">
                  <span className="searchicon"></span>
                  <input
                    type="text"
                    name="search"
                    value={search}
                    placeholder="search for ?"
                    onChange={(e) => {
                      setinsearch(e.target.value)
                    }}
                  />
                </div>
              </div>
              <div className="nav-1 loginbox">
                <div className="login">
                  <Link to="/signin">Login</Link>
                </div>
                <div className="register">
                  <Link to="/register">register</Link>
                </div>
              </div>
            </div>
            <div className="navin">
              <div className="nav-1">
                <span
                  className="menuicon"
                  onClick={() => {
                    menuclick()
                  }}
                ></span>
                <Link to="/">
                  <span className="logoen"></span>
                </Link>
              </div>

              <div className="nav-1 washcart">
                <Link to="/washlist">
                  <span className="lovecart"></span>
                </Link>
                <Link to="/cart">
                  <span className="shopingcart"></span>
                </Link>
              </div>
            </div>
          </div>
          <div className="sidbar" style={navstyle}>
            <div className="slider-sl">
              <div className="uloginnavbar">
                <div className="slider-bar">
                  <Link to="/signin">
                    <div className="btn1">Signin</div>
                  </Link>
                  <Link to="/register">
                    <div className="btn1">register</div>
                  </Link>
                </div>
                <div className="slider-bar usersection">
                  <Link to="/profile">
                    <div className="user_in">
                      <div className="userimage_in">
                        <img
                          src={
                            'https://platform-lookaside.fbsbx.com/platform/profilepic/?asid=2773166466147063&height=50&width=50&ext=1669015000&hash=AeRklo6_g0yOgqmoBlY'
                          }
                          alt="userimage"
                        />
                      </div>
                      <div className="username">Mostafa Briek</div>
                    </div>
                  </Link>
                </div>
              </div>
              <div>
                <div className="slider-bar sortbyhumen">
                  <div>Men</div>
                  <div>Women</div>
                  <div>Kids</div>
                </div>

                <div className="slider-bar slidercatogorybar">
                  <div>Shoes</div>
                  <div>sports wears</div>
                  <div>outfit</div>
                </div>
                <div className="slider-bar weboptions">
                  <Link to="/lang">
                    <div>Language and country (EGYPT - English)</div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="navbar ar">
            <div className="topnav">
              <Link to="/lang">
                <div className="nav-1">مصر - العربيه</div>
              </Link>
              <div className="nav-1">
                <div className="search">
                  <span className="searchicon"></span>
                  <input
                    type="text"
                    name="search"
                    value={search}
                    placeholder="تبحث عن ؟"
                    onChange={(e) => {
                      setinsearch(e.target.value)
                    }}
                  />
                </div>
              </div>
              <div className="nav-1 loginbox">
                <div className="login">
                  <Link to="/signin">تسجيل دخول</Link>
                </div>
                <div className="register">
                  <Link to="/register">تسجيل</Link>
                </div>
              </div>
            </div>
            <div className="navin">
              <div className="nav-1">
                <span
                  className="menuicon"
                  onClick={() => {
                    menuclick()
                  }}
                ></span>
                <Link to="/">
                  <span className="logoar"></span>
                </Link>
              </div>

              <div className="nav-1 washcart">
                <Link to="/washlist">
                  <span className="lovecart"></span>
                </Link>
                <Link to="/cart">
                  <span className="shopingcart"></span>
                </Link>
              </div>
            </div>
          </div>
          <div className="sidbar" style={navstyle}>
            <div className="slider-sl">
              <div className="uloginnavbar">
                <div className="slider-bar">
                  <Link to="/signin">
                    <div className="btn1">تسجيل دخول</div>
                  </Link>
                  <Link to="/register">
                    <div className="btn1">تسجيل</div>
                  </Link>
                </div>
                <div className="slider-bar usersection">
                  <Link to="/profile">
                    <div className="user_in">
                      <div className="userimage_in">
                        <img
                          src={
                            'https://platform-lookaside.fbsbx.com/platform/profilepic/?asid=2773166466147063&height=50&width=50&ext=1669015000&hash=AeRklo6_g0yOgqmoBlY'
                          }
                          alt="userimage"
                        />
                      </div>
                      <div className="username">Mostafa Briek</div>
                    </div>
                  </Link>
                </div>
              </div>
              <div>
                <div className="slider-bar sortbyhumen">
                  <div>رجالي</div>
                  <div>نسائي</div>
                  <div>اطفال</div>
                </div>
                <div className="slider-bar slidercatogorybar">
                  <div>احذية</div>
                  <div>ملابس رياضية</div>
                  <div>موضة</div>
                </div>
                <div className="slider-bar weboptions">
                  <Link to="/lang">
                    <div>اللغة و الدولة (مصر - العربية)</div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )
}
