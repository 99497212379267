import React from 'react'

export default function privacypolicy() {
  return (
    <>
      {localStorage.getItem('Lang') && localStorage.getItem('Lang') === 'en' ? (
        <>
          <div className="page">
            <div className="title">Privacy Policy for Correct Store</div>

            <p>
              At Correct Store, accessible from correctstore.com, one of our
              main priorities is the privacy of our visitors. This Privacy
              Policy document contains types of information that is collected
              and recorded by Correct Store and how we use it.
            </p>

            <p>
              If you have additional questions or require more information about
              our Privacy Policy, do not hesitate to contact us.
            </p>

            <p>
              This Privacy Policy applies only to our online activities and is
              valid for visitors to our website with regards to the information
              that they shared and/or collect in Correct Store. This policy is
              not applicable to any information collected offline or via
              channels other than this website.
            </p>

            <h2>Consent</h2>

            <p>
              By using our website, you hereby consent to our Privacy Policy and
              agree to its terms.
            </p>

            <h2>Information we collect</h2>

            <p>
              The personal information that you are asked to provide, and the
              reasons why you are asked to provide it, will be made clear to you
              at the point we ask you to provide your personal information.
            </p>
            <p>
              If you contact us directly, we may receive additional information
              about you such as your name, email address, phone number, the
              contents of the message and/or attachments you may send us, and
              any other information you may choose to provide.
            </p>
            <p>
              When you register for an Account, we may ask for your contact
              information, including items such as name, company name, address,
              email address, and telephone number.
            </p>

            <h2>How we use your information</h2>

            <p>
              We use the information we collect in various ways, including to:
            </p>

            <ul>
              <li>Provide, operate, and maintain our website</li>
              <li>Improve, personalize, and expand our website</li>
              <li>Understand and analyze how you use our website</li>
              <li>
                Develop new products, services, features, and functionality
              </li>
              <li>
                Communicate with you, either directly or through one of our
                partners, including for customer service, to provide you with
                updates and other information relating to the website, and for
                marketing and promotional purposes
              </li>
              <li>Send you emails</li>
              <li>Find and prevent fraud</li>
            </ul>

            <h2>Log Files</h2>

            <p>
              Correct Store follows a standard procedure of using log files.
              These files log visitors when they visit websites. All hosting
              companies do this and a part of hosting services' analytics. The
              information collected by log files include internet protocol (IP)
              addresses, browser type, Internet Service Provider (ISP), date and
              time stamp, referring/exit pages, and possibly the number of
              clicks. These are not linked to any information that is personally
              identifiable. The purpose of the information is for analyzing
              trends, administering the site, tracking users' movement on the
              website, and gathering demographic information.
            </p>

            <h2>Cookies and Web Beacons</h2>

            <p>
              Like any other website, Correct Store uses 'cookies'. These
              cookies are used to store information including visitors'
              preferences, and the pages on the website that the visitor
              accessed or visited. The information is used to optimize the
              users' experience by customizing our web page content based on
              visitors' browser type and/or other information.
            </p>

            <h2>Our Advertising Partners</h2>

            <p>
              Some of advertisers on our site may use cookies and web beacons.
              Our advertising partners are listed below. Each of our advertising
              partners has their own Privacy Policy for their policies on user
              data. For easier access, we hyperlinked to their Privacy Policies
              below.
            </p>

            <ul>
              <li>
                <p>Google</p>
                <p>
                  <a href="https://policies.google.com/technologies/ads">
                    https://policies.google.com/technologies/ads
                  </a>
                </p>
              </li>
            </ul>

            <h2>Advertising Partners Privacy Policies</h2>

            <p>
              You may consult this list to find the Privacy Policy for each of
              the advertising partners of Correct Store.
            </p>

            <p>
              Third-party ad servers or ad networks uses technologies like
              cookies, JavaScript, or Web Beacons that are used in their
              respective advertisements and links that appear on Correct Store,
              which are sent directly to users' browser. They automatically
              receive your IP address when this occurs. These technologies are
              used to measure the effectiveness of their advertising campaigns
              and/or to personalize the advertising content that you see on
              websites that you visit.
            </p>

            <p>
              Note that Correct Store has no access to or control over these
              cookies that are used by third-party advertisers.
            </p>

            <h2>Third Party Privacy Policies</h2>

            <p>
              Correct Store's Privacy Policy does not apply to other advertisers
              or websites. Thus, we are advising you to consult the respective
              Privacy Policies of these third-party ad servers for more detailed
              information. It may include their practices and instructions about
              how to opt-out of certain options.{' '}
            </p>

            <p>
              You can choose to disable cookies through your individual browser
              options. To know more detailed information about cookie management
              with specific web browsers, it can be found at the browsers'
              respective websites.
            </p>

            <h2>CCPA Privacy Rights (Do Not Sell My Personal Information)</h2>

            <p>
              Under the CCPA, among other rights, California consumers have the
              right to:
            </p>
            <p>
              Request that a business that collects a consumer's personal data
              disclose the categories and specific pieces of personal data that
              a business has collected about consumers.
            </p>
            <p>
              Request that a business delete any personal data about the
              consumer that a business has collected.
            </p>
            <p>
              Request that a business that sells a consumer's personal data, not
              sell the consumer's personal data.
            </p>
            <p>
              If you make a request, we have one month to respond to you. If you
              would like to exercise any of these rights, please contact us.
            </p>

            <h2>GDPR Data Protection Rights</h2>

            <p>
              We would like to make sure you are fully aware of all of your data
              protection rights. Every user is entitled to the following:
            </p>
            <p>
              The right to access – You have the right to request copies of your
              personal data. We may charge you a small fee for this service.
            </p>
            <p>
              The right to rectification – You have the right to request that we
              correct any information you believe is inaccurate. You also have
              the right to request that we complete the information you believe
              is incomplete.
            </p>
            <p>
              The right to erasure – You have the right to request that we erase
              your personal data, under certain conditions.
            </p>
            <p>
              The right to restrict processing – You have the right to request
              that we restrict the processing of your personal data, under
              certain conditions.
            </p>
            <p>
              The right to object to processing – You have the right to object
              to our processing of your personal data, under certain conditions.
            </p>
            <p>
              The right to data portability – You have the right to request that
              we transfer the data that we have collected to another
              organization, or directly to you, under certain conditions.
            </p>
            <p>
              If you make a request, we have one month to respond to you. If you
              would like to exercise any of these rights, please contact us.
            </p>

            <h2>Children's Information</h2>

            <p>
              Another part of our priority is adding protection for children
              while using the internet. We encourage parents and guardians to
              observe, participate in, and/or monitor and guide their online
              activity.
            </p>

            <p>
              Correct Store does not knowingly collect any Personal Identifiable
              Information from children under the age of 13. If you think that
              your child provided this kind of information on our website, we
              strongly encourage you to contact us immediately and we will do
              our best efforts to promptly remove such information from our
              records.
            </p>
          </div>
        </>
      ) : (
        <>
          <div class="page ar">
            <div class="title">سياسة الخصوصية الخاصة بكوركت ستور</div>
            <p>
              في Correct Store ، الذي يمكن الوصول إليه منrectstore.com ، تتمثل
              إحدى أولوياتنا الرئيسية في خصوصية زوارنا. تحتوي وثيقة سياسة
              الخصوصية هذه على أنواع المعلومات التي يتم جمعها وتسجيلها بواسطة
              Correct Store وكيفية استخدامها.
            </p>
            <p>
              إذا كانت لديك أسئلة إضافية أو تحتاج إلى مزيد من المعلومات حول
              سياسة الخصوصية الخاصة بنا ، فلا تتردد في الاتصال بنا.
            </p>
            <p>
              تنطبق سياسة الخصوصية هذه فقط على أنشطتنا عبر الإنترنت وهي صالحة
              لزوار موقعنا الإلكتروني فيما يتعلق بالمعلومات التي شاركوها و / أو
              يجمعونها في كوركت ستور. لا تنطبق هذه السياسة على أي معلومات يتم
              جمعها في وضع عدم الاتصال أو عبر قنوات أخرى غير هذا الموقع.
            </p>
            <h2>موافقة</h2>
            <p>
              باستخدام موقعنا ، فإنك بذلك توافق على سياسة الخصوصية الخاصة بنا
              وتوافق على شروطها.
            </p>
            <h2>المعلومات التي نجمعها</h2>
            <p>
              سيتم توضيح المعلومات الشخصية التي يُطلب منك تقديمها ، وأسباب
              مطالبتك بتقديمها ، في الوقت الذي نطلب منك فيه تقديم معلوماتك
              الشخصية.
            </p>
            <p>
              إذا اتصلت بنا مباشرة ، فقد نتلقى معلومات إضافية عنك مثل اسمك
              وعنوان بريدك الإلكتروني ورقم هاتفك ومحتويات الرسالة و / أو
              المرفقات التي قد ترسلها إلينا وأي معلومات أخرى قد تختار تقديمها.
            </p>
            <p>
              عند التسجيل للحصول على حساب ، قد نطلب معلومات الاتصال الخاصة بك ،
              بما في ذلك عناصر مثل الاسم واسم الشركة والعنوان وعنوان البريد
              الإلكتروني ورقم الهاتف.
            </p>
            <h2>كيف نستخدم معلوماتك</h2>
            <p>نستخدم المعلومات التي نجمعها بطرق مختلفة ، بما في ذلك:</p>
            <ul>
              <li>توفير وتشغيل وصيانة موقعنا</li>
              <li>تحسين وتخصيص وتوسيع موقعنا</li>
              <li>فهم وتحليل كيفية استخدامك لموقعنا</li>
              <li>تطوير منتجات وخدمات وميزات ووظائف جديدة</li>
              <li>
                التواصل معك ، إما بشكل مباشر أو من خلال أحد شركائنا ، بما في ذلك
                لخدمة العملاء ، لتزويدك بالتحديثات والمعلومات الأخرى المتعلقة
                بالموقع ولأغراض تسويقية وترويجية
              </li>
              <li>أرسل لك رسائل البريد الإلكتروني</li>
              <li>البحث عن ومنع الاحتيال</li>
            </ul>
            <h2>ملفات الدخول</h2>
            <p>
              يتبع Correct Store إجراءً قياسيًا لاستخدام ملفات السجل. تسجل هذه
              الملفات الزوار عندما يزورون مواقع الويب. تقوم جميع شركات الاستضافة
              بذلك وجزء من تحليلات خدمات الاستضافة. تتضمن المعلومات التي يتم
              جمعها بواسطة ملفات السجل عناوين بروتوكول الإنترنت (IP) ونوع
              المستعرض وموفر خدمة الإنترنت (ISP) وختم التاريخ والوقت وصفحات
              الإحالة / الخروج وربما عدد النقرات. هذه ليست مرتبطة بأي معلومات
              لتحديد الهوية الشخصية. الغرض من المعلومات هو تحليل الاتجاهات
              وإدارة الموقع وتتبع حركة المستخدمين على الموقع وجمع المعلومات
              الديموغرافية.
            </p>
            <h2>ملفات تعريف الارتباط وإشارات الويب</h2>
            <p>
              مثل أي موقع ويب آخر ، يستخدم Correct Store "ملفات تعريف الارتباط".{' '}
              تُستخدم ملفات تعريف الارتباط هذه لتخزين المعلومات بما في ذلك
              تفضيلات الزوار والصفحات الموجودة على موقع الويب التي قام الزائر
              بالوصول إليها أو زيارتها. تُستخدم المعلومات لتحسين تجربة
              المستخدمين من خلال تخصيص محتوى صفحة الويب الخاصة بنا بناءً على نوع
              متصفح الزوار و / أو معلومات أخرى.
            </p>
            <h2>شركاؤنا في الإعلانات</h2>
            <p>
              قد يستخدم بعض المعلنين على موقعنا ملفات تعريف الارتباط وإشارات
              الويب. شركاء الإعلان لدينا مدرجون أدناه. لكل من شركائنا الإعلانيين
              سياسة الخصوصية الخاصة بهم لسياساتهم الخاصة ببيانات المستخدم.{' '}
              لتسهيل الوصول ، قمنا بالربط التشعبي بسياسات الخصوصية الخاصة بهم
              أدناه.
            </p>
            <ul>
              <li>
                <p>جوجل</p>
                <p>
                  <a href="https://policies.google.com/technologies/ads">
                    https://policies.google.com/technologies/ads
                  </a>
                </p>
              </li>
            </ul>
            <h2>سياسات خصوصية شركاء الإعلانات</h2>
            <p>
              يمكنك الرجوع إلى هذه القائمة للعثور على سياسة الخصوصية لكل من
              شركاء الإعلان في Correct Store.
            </p>
            <p>
              تستخدم خوادم الإعلانات الخارجية أو شبكات الإعلانات تقنيات مثل
              ملفات تعريف الارتباط أو جافا سكريبت أو إشارات الويب المستخدمة في
              الإعلانات والروابط الخاصة بكل منها والتي تظهر على Correct Store ،
              والتي يتم إرسالها مباشرة إلى متصفح المستخدمين. يتلقون عنوان IP
              الخاص بك تلقائيًا عند حدوث ذلك. تُستخدم هذه التقنيات لقياس فعالية
              حملاتهم الإعلانية و / أو لتخصيص محتوى الإعلان الذي تراه على مواقع
              الويب التي تزورها.
            </p>
            <p>
              لاحظ أن Correct Store ليس لديه حق الوصول إلى ملفات تعريف الارتباط
              هذه أو التحكم فيها التي يستخدمها معلنون تابعون لجهات خارجية.
            </p>
            <h2>سياسات خصوصية الطرف الثالث</h2>
            <p>
              لا تنطبق سياسة خصوصية Correct Store على المعلنين أو مواقع الويب
              الأخرى. وبالتالي ، فإننا ننصحك بالرجوع إلى سياسات الخصوصية الخاصة
              بخوادم إعلانات الجهات الخارجية للحصول على معلومات أكثر تفصيلاً. قد
              يتضمن ممارساتهم وتعليماتهم حول كيفية الانسحاب من بعض الخيارات.{' '}
            </p>
            <p>
              يمكنك اختيار تعطيل ملفات تعريف الارتباط من خلال خيارات المتصفح
              الفردية الخاصة بك. لمعرفة المزيد من المعلومات التفصيلية حول إدارة
              ملفات تعريف الارتباط مع متصفحات الويب المحددة ، يمكن العثور عليها
              في مواقع الويب الخاصة بالمتصفحات.
            </p>
            <h2>حقوق خصوصية CCPA (لا تبيع معلوماتي الشخصية)</h2>
            <p>
              بموجب قانون حماية خصوصية المستهلك في كاليفورنيا ، من بين حقوق أخرى
              ، يحق للمستهلكين في كاليفورنيا:
            </p>
            <p>
              اطلب من الشركة التي تجمع البيانات الشخصية للمستهلك أن تكشف عن فئات
              وأجزاء معينة من البيانات الشخصية التي جمعتها الشركة عن المستهلكين.
            </p>
            <p>
              اطلب من شركة ما حذف أي بيانات شخصية عن المستهلك جمعتها الشركة.
            </p>
            <p>
              اطلب من الشركة التي تبيع البيانات الشخصية للمستهلك ، ألا تبيع
              البيانات الشخصية للمستهلك.
            </p>
            <p>
              إذا قمت بتقديم طلب ، فلدينا شهر واحد للرد عليك. إذا كنت ترغب في
              ممارسة أي من هذه الحقوق ، يرجى الاتصال بنا.
            </p>
            <h2>حقوق حماية بيانات القانون العام لحماية البيانات (GDPR)</h2>
            <p>
              نود التأكد من أنك على دراية كاملة بجميع حقوق حماية البيانات الخاصة
              بك. يحق لكل مستخدم ما يلي:
            </p>
            <p>
              الحق في الوصول - يحق لك طلب نسخ من بياناتك الشخصية. قد نفرض عليك
              رسومًا رمزية مقابل هذه الخدمة.
            </p>
            <p>
              الحق في التصحيح - يحق لك أن تطلب منا تصحيح أي معلومات تعتقد أنها
              غير دقيقة. لديك أيضًا الحق في طلب إكمال المعلومات التي تعتقد أنها
              غير كاملة.
            </p>
            <p>
              الحق في المسح - يحق لك طلب مسح بياناتك الشخصية ، في ظل ظروف معينة.
            </p>
            <p>
              الحق في تقييد المعالجة - يحق لك طلب تقييد معالجة بياناتك الشخصية ،
              في ظل ظروف معينة.
            </p>
            <p>
              الحق في الاعتراض على المعالجة - يحق لك الاعتراض على معالجتنا
              لبياناتك الشخصية ، في ظل ظروف معينة.
            </p>
            <p>
              الحق في نقل البيانات - يحق لك أن تطلب منا نقل البيانات التي
              جمعناها إلى مؤسسة أخرى ، أو إليك مباشرةً ، في ظل ظروف معينة.
            </p>
            <p>
              إذا قمت بتقديم طلب ، فلدينا شهر واحد للرد عليك. إذا كنت ترغب في
              ممارسة أي من هذه الحقوق ، يرجى الاتصال بنا.
            </p>
            <h2>معلومات الأطفال</h2>
            <p>
              جزء آخر من أولوياتنا هو إضافة حماية للأطفال أثناء استخدام
              الإنترنت. نشجع الآباء والأوصياء على مراقبة نشاطهم عبر الإنترنت
              والمشاركة فيه و / أو مراقبته وتوجيهه.
            </p>
            <p>
              لا يجمع Correct Store عن قصد أي معلومات تعريف شخصية من الأطفال دون
              سن 13 عامًا. إذا كنت تعتقد أن طفلك قدم هذا النوع من المعلومات على
              موقعنا ، فنحن نشجعك بشدة على الاتصال بنا على الفور وسنبذل قصارى
              جهدنا على الفور إزالة هذه المعلومات من سجلاتنا.
            </p>
          </div>
        </>
      )}
    </>
  )
}
