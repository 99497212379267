import React, { useEffect, useState } from 'react'
// import FacebookLogin from '@greatsumini/react-facebook-login'
import FacebookLogin from 'react-facebook-login'

import { GoogleLogin } from 'react-google-login'
import { gapi } from 'gapi-script'
import Inputfiled from '../scenes/inputfiled'
import '../../style/sign.css'
export default function Signup() {
  gapi.load('client:auth2', () => {
    gapi.client.init({
      clientId:
        '710735828051-78ugm5f0q332c82jc9pip6p9qvuijj9e.apps.googleusercontent.com',
      plugin_name: 'chat',
    })
  })

  const responseGoogle = (response) => {
    console.log(response)
  }
  //   const responsefacebook = (response) => {
  //     console.log('Login Success!', response)
  //   }
  const [username, setusername] = useState()
  //   const [password, setpassword] = useState()
  useEffect(() => {
    document.title = 'Signin page'
  }, [])

  if (document.getElementById('username')) {
    const usernameelement = document.getElementById('username')
    setusername(usernameelement.value)
    console.log(username)
  }

  return (
    <>
      {localStorage.getItem('Lang') && localStorage.getItem('Lang') === 'en' ? (
        <>
          <div className="page">
            <div className="title">Register</div>
            <div className="pageinpage">
              <div className="section login">
                <div className="title">Register By Email</div>
                <div className="logininwebsite">
                  <Inputfiled
                    placeholder="Enter Your Name"
                    type="text"
                    name="username"
                  />
                  <Inputfiled
                    placeholder="Enter Your Email"
                    type="text"
                    name="email"
                  />
                  <Inputfiled
                    placeholder="Enter Your Password"
                    type="password"
                    name="password"
                    mood="password"
                  />
                  <Inputfiled
                    placeholder="Re Enter Your Password"
                    type="password"
                    name="repassword"
                    mood="password"
                  />

                  <span className="btn login">Register</span>
                </div>
              </div>
              <div className="section login">
                <div className="title">Register By sociel Media</div>

                <FacebookLogin
                  appId="491305399611871"
                  //   onSuccess={responsefacebook}
                  onFail={(error) => {
                    console.log('Login Failed!', error)
                  }}
                  onProfileSuccess={(response) => {
                    console.log('Get Profile Success!', response)
                  }}
                />

                <GoogleLogin
                  clientId="710735828051-78ugm5f0q332c82jc9pip6p9qvuijj9e.apps.googleusercontent.com"
                  buttonText="Login"
                  onSuccess={responseGoogle}
                  onFailure={responseGoogle}
                  isSignedIn={true}
                  cookiePolicy={'single_host_origin'}
                />
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="page ar">
            <div className="title">تسجيل </div>
          </div>
        </>
      )}
    </>
  )
}
