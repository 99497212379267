import React, { useEffect } from 'react'
import '../../style/lang.css'
import { useNavigate } from 'react-router-dom'

export default function Lang() {
  const navigate = useNavigate()

  useEffect(() => {
    var arabic = document.querySelector('.arabic')
    var english = document.querySelector('.english')
    var currentlang = localStorage.getItem('Lang')
    if (currentlang === 'en') {
      english.classList.add('active')
    }
    if (currentlang === 'ar') {
      arabic.classList.add('active')
    }
  }, [])
  function changelang(lang) {
    var arabic = document.querySelector('.arabic')
    var english = document.querySelector('.english')
    if (!localStorage.getItem('Lang')) {
      localStorage.setItem('Lang', 'ar')
    } else {
      if (lang === 'en') {
        localStorage.setItem('Lang', 'en')
        arabic.classList.remove('active')
        english.classList.add('active')
      }
      if (lang === 'ar') {
        localStorage.setItem('Lang', 'ar')
        arabic.classList.add('active')
        english.classList.remove('active')
      }
    }
    navigate(-1)
    setTimeout(function () {
      window.location.reload(1)
    }, 1000)
  }
  return (
    <>
      {localStorage.getItem('Lang') && localStorage.getItem('Lang') === 'en' ? (
        <>
          <div className="countryandlang">
            <div className="title">country</div>
            <div className="countrylist">
              <div className="country active">Egypt</div>
              {/* <div className="country">Kuwait</div> */}
            </div>
          </div>
          <div className="countryandlang">
            <div className="title">Language</div>
            <div className="countrylist">
              <div
                className="country english"
                onClick={(e) => {
                  changelang('en')
                }}
              >
                English
              </div>
              <div
                className="country arabic"
                onClick={(e) => {
                  changelang('ar')
                }}
              >
                Arabic
              </div>
            </div>
          </div>
          <div className="countryandlang">
            <div className="title">currency</div>
            <div className="countrylist">
              <div className="country active">Egypt Pound - EGP</div>
              {/* <div className="country">Kuwait Dinar - D.K</div> */}
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="countryandlang ar">
            <div className="title">الدولة</div>
            <div className="countrylist">
              <div className="country active">مصر</div>
              {/* <div className="country">Kuwait</div> */}
            </div>
          </div>
          <div className="countryandlang ar">
            <div className="title">اللغة</div>
            <div className="countrylist">
              <div
                className="country english"
                onClick={(e) => {
                  changelang('en')
                }}
              >
                English
              </div>
              <div
                className="country arabic"
                onClick={(e) => {
                  changelang('ar')
                }}
              >
                العربية
              </div>
            </div>
          </div>
          <div className="countryandlang ar">
            <div className="title">العملة</div>
            <div className="countrylist">
              <div className="country active">الجنيه المصري - ج.م</div>
              {/* <div className="country">Kuwait Dinar - D.K</div> */}
            </div>
          </div>
        </>
      )}
    </>
  )
}
