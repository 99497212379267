import React, { useState, useEffect } from 'react'
import '../../style/cover.css'

export default function Cover(props) {
  const [cover, setcover] = useState(0)
  const [coverimage, setcoverimage] = useState('')
  useEffect(() => {
    setTimeout(() => {
      setcover(1)
    }, 1000)
  }, [])

  useEffect(() => {
    const cover1 = props.coverimage
    const cover2 = props.coverimage2
    setcoverimage(cover1)
    setTimeout(() => {
      setcoverimage(cover2)
    }, 10000)
  }, [props])

  return (
    <>
      {cover & (cover === 1) ? (
        <>
          <div className="homecover">
            <div className="cover">
              <img
                src={require(`../../data/in/${coverimage}`)}
                alt="homecover"
              />
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="homecover">
            <div className="cover cover-background"></div>
          </div>
        </>
      )}
    </>
  )
}
